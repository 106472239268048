import $http from '../../../utils/http.js';

/**
 * 个人设置账户信息
 * @param data
 */
export function getAccountInfoData(data) {
    return $http.get('/usermx/index', data, 'loadingDiv');
}

/**
 * 个人设置修改头像
 * @param data
 */
export function modifyAvatarAction(data) {
    return $http.post('/usermx/upload', data, 'loadingDiv', 'multipart/form-data;charset=utf-8');
}

/**
 * 个人设置修改密码
 * @param data
 */
export function modifyPasswordAction(data) {
    return $http.post('/usermx/editpwd', data, 'loadingDiv');
}

/**
 * 个人设置修改密码
 * @param data
 */
export function modifyNameAction(data) {
    return $http.post('/usermx/editrealname', data, 'loadingDiv');
}

/**
 * 个人设置修改密码
 * @param data
 */
export function modifyEmailAction(data) {
    return $http.post('/usermx/editemail', data, 'loadingDiv');
}
