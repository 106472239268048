<template>
    <div style="height: 50px"></div>
    <div class="account-info-page">
        <h3 style="border-bottom: 1px solid #ececec; text-align: center; padding: 0 0 30px">个人信息</h3>
        <div class="account-info-content">
            <el-form ref="accountInfoForm" :model="accountInfoForm" label-width="80px" label-position="right"
                class="accountInfoForm">
                <el-form-item>
                    <el-upload class="avatar-uploader" action="#" ref="uploadAvatar"
                        accept="image/gif,image/jpeg,image/jpg,image/png,image/svg" :multiple="false"
                        :show-file-list="false" :limit="1" :http-request="uploadAvatar">
                        <img v-if="accountInfoForm.avatar" :src="accountInfoForm.avatar" class="avatar" />
                        <el-button type="text" class="change-avatar">修改头像</el-button>
                    </el-upload>
                </el-form-item>
                <el-form-item label="用户名：">{{ accountInfoForm.userName }}</el-form-item>
                <el-form-item label="密码：">
                    <el-row>
                        <el-col :span="12">
                            <div>{{ accountInfoForm.password }}</div>
                        </el-col>
                        <el-col :span="10" style="text-align: end">
                            <el-button type="text" style="font-size: 16px" :disabled="!permissionsAction.editPwd"
                                @click="handleModifyPasswordBtn(accountInfoForm.password)">修改</el-button>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="姓名：">
                    <el-row>
                        <el-col :span="12">
                            <div>{{ accountInfoForm.name }}</div>
                        </el-col>
                        <el-col :span="10" style="text-align: end">
                            <el-button type="text" style="font-size: 16px" :disabled="!permissionsAction.editRealName"
                                @click="handleModifyNameBtn(accountInfoForm.name)">修改</el-button>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="权限：">
                    <el-row>
                        <el-col :span="12">
                            <div style="width: 400px">{{ accountInfoForm.permissions }}</div>
                        </el-col>
                        <!-- <el-col :span="10" style="text-align: end">
                            <el-button type="text" style="font-size: 16px">修改</el-button>
                        </el-col> -->
                    </el-row>
                </el-form-item>
                <el-form-item label="邮箱：">
                    <el-row>
                        <el-col :span="12">
                            <div>{{ accountInfoForm.email }}</div>
                        </el-col>
                        <el-col :span="10" style="text-align: end">
                            <el-button type="text" style="font-size: 16px" :disabled="!permissionsAction.editEmail"
                                @click="handleModifyEamilBtn(accountInfoForm.email)">修改</el-button>
                        </el-col>
                    </el-row>
                </el-form-item>
            </el-form>
        </div>
    </div>
    <!-- 修改密码弹窗 -->
    <dialogFrame :show="dialogFormModifyPasswordVisible" @showDialog="showDialog" :title="'修改密码'" :footer="false"
        width="540px" center>
        <el-form ref="modifyForm" :model="modifyForm" :rules="rules" label-width="120px" label-position="right">
            <el-form-item label="新密码：" prop="password">
                <el-input class="w-260" size="small" type="password" v-model="modifyForm.password" maxlength="18"
                    autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="确认密码：" prop="confirmPassword">
                <el-input class="w-260" size="small" type="password" v-model="modifyForm.confirmPassword" maxlength="18"
                    autocomplete="off"></el-input>
            </el-form-item>
            <div class="modify-save-btn">
                <el-button type="primary" @click="dialogFormModifyPasswordVisible = false">取消</el-button>
                <el-button type="primary" @click="handleSavePasswordBtn">保存</el-button>
            </div>
        </el-form>
    </dialogFrame>
    <!-- 修改姓名弹窗 -->
    <dialogFrame :show="dialogFormModifyNameVisible" @showDialog="showDialog" :title="'修改姓名'" :footer="false"
        width="540px" center>
        <el-form ref="modifyForm" :model="modifyForm" :rules="rules" label-width="120px" label-position="right">
            <el-form-item label="新姓名：" prop="name">
                <el-input class="w-260" size="small" v-model="modifyForm.name" maxlength="18" autocomplete="off">
                </el-input>
            </el-form-item>
            <div class="modify-save-btn">
                <el-button type="primary" @click="dialogFormModifyNameVisible = false">取消</el-button>
                <el-button type="primary" @click="handleSaveNameBtn">保存</el-button>
            </div>
        </el-form>
    </dialogFrame>
    <!-- 修改邮箱弹窗 -->
    <dialogFrame :show="dialogFormModifyEmailVisible" @showDialog="showDialog" :title="'修改姓名'" :footer="false"
        width="540px" center>
        <el-form ref="modifyForm" :model="modifyForm" :rules="rules" label-width="120px" label-position="right">
            <el-form-item label="新邮箱：" prop="email">
                <el-input class="w-260" size="small" v-model="modifyForm.email" maxlength="18" autocomplete="off">
                </el-input>
            </el-form-item>
            <div class="modify-save-btn">
                <el-button type="primary" @click="dialogFormModifyEmailVisible = false">取消</el-button>
                <el-button type="primary" @click="handleSaveEmailBtn">保存</el-button>
            </div>
        </el-form>
    </dialogFrame>
</template>

<script>
import dialogFrame from '@/components/common/dialog/dialog-frame.vue';
import { globalDesensitized } from '@/utils/utils.js';
import {
    getAccountInfoData,
    modifyAvatarAction,
    modifyPasswordAction,
    modifyNameAction,
    modifyEmailAction
} from '@/common/api/setting/account-info.js';
export default {
    components: {
        dialogFrame
    },
    data() {
        const validatePassword = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('新密码不能为空'));
            } else {
                if (this.modifyForm.confirmPassword !== '') {
                    this.$refs.modifyForm.validateField('confirmPassword');
                }
                callback();
            }
        };
        const validateConfirmPassword = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('确认密码不能为空'));
            } else if (value !== this.modifyForm.password) {
                callback(new Error('输入的密码不一致'));
            } else {
                callback();
            }
        };
        return {
            accountInfoForm: {
                // 头像
                avatar: '',
                // 用户名
                userName: '',
                // 密码
                password: '******',
                // 姓名
                name: '',
                // 权限
                permissions: '',
                // 邮箱
                email: ''
            },
            modifyForm: {
                // 新密码
                password: '',
                // 确认密码
                confirmPassword: '',
                // 新姓名
                name: '',
                // 新邮箱
                email: ''
            },
            rules: {
                password: [
                    {
                        required: true,
                        validator: validatePassword,
                        trigger: 'blur'
                    },
                    {
                        pattern: /^[A-Za-z0-9]+$/,
                        message: '密码只能是英文和数字组成，长度在18个字符内',
                        trigger: 'blur'
                    }
                ],
                confirmPassword: [
                    {
                        required: true,
                        validator: validateConfirmPassword,
                        trigger: 'blur'
                    },
                    {
                        pattern: /^[A-Za-z0-9]+$/,
                        message: '密码只能是英文和数字组成，长度在18个字符内',
                        trigger: 'blur'
                    }
                ],
                name: [
                    {
                        required: true,
                        message: '新姓名不能为空',
                        trigger: 'blur'
                    },
                    {
                        pattern: /^[\u4E00-\u9FA5A-Za-z]+$/,
                        message: '新姓名只能是中文和英文组成',
                        trigger: 'blur'
                    }
                ],
                email: [
                    {
                        required: true,
                        message: '新邮箱不能为空',
                        trigger: 'blur'
                    },
                    {
                        pattern: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                        message: '新邮箱格式不正确，不含中文，开头必须是英文或者数字',
                        trigger: 'blur'
                    }
                ]
            },
            // 是否显示密码弹窗
            dialogFormModifyPasswordVisible: false,
            // 是否显示姓名弹窗
            dialogFormModifyNameVisible: false,
            // 是否显示邮箱弹窗
            dialogFormModifyEmailVisible: false,
            // 权限操作集合
            permissionsAction: {}
        };
    },
    mounted() {
        this.queryAccountInfoData();
    },
    methods: {
        showDialog(val) {
            this.dialogFormModifyPasswordVisible = val;
            this.dialogFormModifyNameVisible = val;
            this.dialogFormModifyEmailVisible = val;
        },
        hidePassword(value) {
            let result = globalDesensitized(value);
            return result;
        },
        // 查询账户信息
        queryAccountInfoData() {
            getAccountInfoData()
                .then(res => {
                    let { code, UserInfos, Power } = res;
                    if (code == 200) {
                        let {
                            Avatar: avatar,
                            UserName: userName,
                            RealName: name,
                            RoleName: permissions,
                            Email: email
                        } = UserInfos;
                        this.accountInfoForm = {
                            ...this.accountInfoForm,
                            avatar,
                            userName,
                            name,
                            permissions,
                            email
                        };
                        // console.log(this.accountInfoForm);
                        this.permissionsAction = Power;
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
        },
        // 处理修改头像
        uploadAvatar(file) {
            let token = this.$store.getters.token;
            // console.log(token);
            let formData = new FormData();
            // formData.append('token', token);
            formData.append('image', file.file);
            modifyAvatarAction(formData)
                .then(res => {
                    let { code, msg, Avatar: avatar } = res;
                    if (code === 200) {
                        this.$message({
                            type: 'success',
                            message: `${msg}`,
                            center: true
                        });
                        this.accountInfoForm.avatar = avatar;
                        this.queryAccountInfoData();
                        this.$refs.uploadAvatar.clearFiles();
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
        },
        // 处理修改密码按钮
        handleModifyPasswordBtn(value) {
            this.dialogFormModifyPasswordVisible = true;
        },
        // 处理保存密码按钮
        handleSavePasswordBtn() {
            this.$refs.modifyForm.validate(valid => {
                if (valid) {
                    let params = {
                        Password: this.modifyForm.password,
                        RePassword: this.modifyForm.confirmPassword
                    };
                    modifyPasswordAction(params)
                        .then(res => {
                            if (res.code == 200) {
                                this.dialogFormModifyPasswordVisible = false;
                                this.queryAccountInfoData();
                            }
                        })
                        .catch(err => {
                            // console.log(err);
                        });
                } else {
                    // console.log('error submit!!');
                    return false;
                }
            });
        },
        // 处理修改姓名按钮
        handleModifyNameBtn(value) {
            this.modifyForm.name = value;
            this.dialogFormModifyNameVisible = true;
        },
        // 处理保存姓名按钮
        handleSaveNameBtn() {
            this.$refs.modifyForm.validate(valid => {
                if (valid) {
                    let params = {
                        RealName: this.modifyForm.name
                    };
                    modifyNameAction(params)
                        .then(res => {
                            if (res.code == 200) {
                                this.dialogFormModifyNameVisible = false;
                                this.queryAccountInfoData();
                            }
                        })
                        .catch(err => {
                            // console.log(err);
                        });
                } else {
                    // console.log('error submit!!');
                    return false;
                }
            });
        },
        // 处理修改邮箱按钮
        handleModifyEamilBtn(value) {
            this.modifyForm.email = value;
            this.dialogFormModifyEmailVisible = true;
        },
        // 处理保存邮箱按钮
        handleSaveEmailBtn() {
            this.$refs.modifyForm.validate(valid => {
                if (valid) {
                    let params = {
                        Email: this.modifyForm.email
                    };
                    modifyEmailAction(params)
                        .then(res => {
                            if (res.code == 200) {
                                this.dialogFormModifyEmailVisible = false;
                                this.queryAccountInfoData();
                            }
                        })
                        .catch(err => {
                            // console.log(err);
                        });
                } else {
                    // console.log('error submit!!');
                    return false;
                }
            });
        }
    }
};
</script>

<style scoped>
.w-260 {
    width: 260px;
}

:deep(.el-form-item__content:nth-child(1)) {
    margin: 0 0 50px 0 !important;
}

:deep(.avatar-uploader:nth-child(1)) {
    margin: auto;
}

:deep(.accountInfoForm .el-form-item:first-child .el-form-item__label) {
    line-height: 75px !important;
}

.avatar-uploader {
    position: relative;
    width: 100px;
    height: 100px;
    border: 1px solid #d9d9d9;
    border-radius: 50%;
    overflow: hidden;
}

.avatar-uploader :deep(.el-upload) {
    border-radius: 50%;
}

.avatar-uploader .change-avatar {
    display: none;
    position: absolute;
    top: 30%;
    left: 50%;
    padding: 0 !important;
    border: none !important;
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -ms-transform: translate(-50%);
    -o-transform: translate(-50%);
    transform: translate(-50%);
}

.avatar-uploader:hover .change-avatar {
    display: block;
}

.avatar {
    width: 100px;
    height: 100px;
    display: block;
    border-radius: 50%;
}

.account-info-page-header {
    position: relative;
}

.account-info-page {
    padding: 20px 20px 50px;
    background-color: white;
    width: 700px;
    margin: 0px auto;
    box-shadow: 0px 0px 4px 1px #ececec;
}

.account-info-content {
    width: 600px;
    margin: 0px auto;
    padding: 0 20px;
}

.modify-save-btn {
    margin-top: 30px;
    text-align: center;
}
</style>
